import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Button from "./button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div>{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hey! My name is `}<strong parentName="p">{`Sam Singh`}</strong>{` and I’m from Long Island, NY 🗽.`}</p>
    <p>{`My focus is on large scale distributed systems in the cloud and writing robust, scaleable software
that’s nice to look at. I also don’t mind getting my hands dirty with hardware as I’m a developer by day and a sysadmin
by night.`}</p>
    <p>{`These days in my free time I usually tend to evergrowing homelab, running or playing Beat Saber. Also a big believer in FOSS and
try to use a FOSS alternative or self-host whenever I can. You can learn more about my equipment and what I use on a daily basis
on `}<a parentName="p" {...{
        "href": "/uses"
      }}><strong parentName="a">{`/uses`}</strong></a>{`.`}</p>
    <h1 style={{
      "textAlign": "center"
    }}>🎓</h1>
    <hr></hr>
    <p>{`Computer Science and Applied Mathematics at `}<strong parentName="p">{`Stony Brook University`}</strong>{`. Go Seawolves 🌊🐺!`}</p>
    <p>{`My coursework was mostly focused on `}<strong parentName="p">{`Distributed Systems`}</strong>{`, `}<strong parentName="p">{`Algorithms`}</strong>{` and `}<strong parentName="p">{`Systems Programming`}</strong>{`.`}</p>
    <br />
    <h1 style={{
      "textAlign": "center"
    }}>👨‍💻</h1>
    <hr></hr>
    <h3>{`Capital One `}<inlineCode parentName="h3">{`NY`}</inlineCode></h3>
    <p>{`As a `}<strong parentName="p">{`Cloud Infrastructure Intern`}</strong>{` I worked on a real-time anomaly detection and remediation platform.`}</p>
    <h3>{`Stony Brook University `}<inlineCode parentName="h3">{`NY`}</inlineCode></h3>
    <p>{`Currently working part time on the `}<strong parentName="p">{`Enterprise Applications and Integrations`}</strong>{` team on maintaining legacy software
and also a new project which is focused on monitoring the health of computer systems across Stony Brook.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      